<template>
  <div class="container has-background-white-ter">
    <section class="section is-primary pb-1">
      <ve-table :fixed-header="true" :columns="getDataTable.headings"
        :table-data="getDataTable.students" />
    </section>
  </div>
</template>


<script>


//import functions from "helpers/functions.js"

export default {
  name: "modal-view-student",

  props: [
    "group",
    "gid"
  ],


  data: function () {
    return {
      showModalFlag: false,
      okPressed: false,
      student: {},
      loading: false,
      tabActive: 'contacts',
      attributes: {},
      localGroup: null
    };
  },

  computed: {




    getDataTable() {

      console.log(this.$store.state.currentGroup?.group?.students ?? [], "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYY")

      let groups = this.$store.state.currentGroup?.group?.students ?? []
      if (groups.length === 0) {
        return []
      }





      let q = groups.reduce((p, c) => {

        console.log({ p, c })

        let megs = { ...c.allData?.MEGS ?? {} }


        let n = Object.keys(megs).filter(x => x.indexOf(":") != -1)

        p.headings = [...new Set([...p.headings, ...n])]
        p.students.push(megs)






        return p

      }, { headings: ["Forename", "Surname"], students: [] })

      q.headings = q.headings.map(x => {
        return { field: x, key: x, title: x }
      })

      return q
    }
  },

  mounted() {
    console.log("I AM BEING MOUNTED")



  },


}
</script>